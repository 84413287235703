<template>
  <span class="visually-hidden">Fondation du Souffle</span>
  <svg width="180" height="121" viewBox="0 0 180 121" fill="none">
    <path
        d="M135.519 4.90628C126.345 5.84834 111.735 15.6818 105.256 33.4698C93.5624 65.5793 104.255 100.319 107.066 107.649C107.108 107.76 107.86 109.862 108.813 109.565C109.676 109.295 109.39 108.057 109.258 107.247C104.059 75.6403 106.071 54.2058 113.836 36.4812C122.941 15.7029 139.388 7.64249 138.779 5.56254C138.451 4.43524 135.73 4.88511 135.524 4.90628H135.519Z"
        fill="#00714D"/>
    <path
        d="M160.991 0.0847297C151.055 1.54016 136.334 12.3157 127.441 33.1892C112.969 67.1564 121.735 109.607 124.577 118.705C124.614 118.832 125.318 121.018 126.165 120.875C127.012 120.732 126.965 119.15 126.864 118.224C123 82.0177 125.885 56.3227 135.418 35.9043C146.408 12.3739 165.014 3.04323 164.231 0.799215C163.834 -0.338669 161.192 0.0476823 160.986 0.0794372L160.991 0.0847297Z"
        fill="#139259"/>
    <path
        d="M108.125 51.9721C108.125 51.9721 96.4316 51.9721 89.116 52.1203C89.5448 48.8549 90.1535 45.69 90.9581 42.5198C94.5895 28.2142 102.71 22.6412 105.589 20.5771C106.304 20.069 106.463 19.6774 106.415 19.3704C106.278 18.5607 104.727 18.4866 104.727 18.4866C103.975 18.3596 89.4442 17.9415 84.0766 37.5872C82.7109 42.578 82.0068 47.7276 81.758 52.8083C77.1845 54.4755 75.7023 59.3869 76.1681 61.0487C77.8356 60.5777 79.6724 60.239 81.6998 60.0273C82.2927 79.6465 88.629 96.519 88.7137 96.7836C88.7137 96.7836 89.2854 98.4878 90.3017 98.2284C91.1064 98.022 90.8734 96.3972 90.8734 96.3972C88.8937 81.0437 87.9779 69.6331 88.4543 59.6992C92.8426 59.5086 101.974 59.6621 101.974 59.6621C101.974 59.6621 109.316 59.588 111.296 59.461C114.435 59.2652 116.706 57.6457 118.701 51.4429C115.673 52.2844 112.455 52.0145 108.13 51.9615L108.125 51.9721Z"
        fill="#005CA9"/>
    <path
        d="M5.29345 39.0849L5.18758 40.3815L5.09759 41.3765H8.05136C8.08312 41.5776 8.08841 41.7999 8.06724 42.0434C8.04607 42.2868 7.99842 42.5091 7.92961 42.7102H4.97584L4.64764 46.3991C4.42531 46.4414 4.15005 46.4626 3.83244 46.4626C3.51483 46.4626 3.24487 46.4414 3.02783 46.3991L3.80068 37.7882L3.89597 37.7141H9.08888C9.12594 37.8941 9.13123 38.1058 9.11006 38.3492C9.06771 38.852 8.74481 39.1007 8.15194 39.1007H6.3945C5.87573 39.1007 5.51048 39.1007 5.28815 39.0902L5.29345 39.0849Z"
        fill="#2A2E53"/>
    <path
        d="M15.007 43.2449C14.9064 44.3828 14.5253 45.2508 13.8689 45.8541C13.3395 46.3357 12.6831 46.5792 11.8891 46.5792C11.0951 46.5792 10.4811 46.3357 10.0417 45.8541C9.49117 45.2508 9.26885 44.3828 9.36942 43.2449C9.47 42.107 9.85113 41.2391 10.5075 40.6357C11.0316 40.1541 11.6933 39.9106 12.4873 39.9106C13.2813 39.9106 13.8954 40.1541 14.3347 40.6357C14.8852 41.2391 15.1076 42.107 15.007 43.2449ZM12.3814 41.1067C11.5821 41.1067 11.1163 41.8212 10.9892 43.2449C10.8622 44.6686 11.201 45.3831 12.0003 45.3831C12.7996 45.3831 13.2654 44.6686 13.3925 43.2449C13.5195 41.8212 13.1807 41.1067 12.3814 41.1067Z"
        fill="#2A2E53"/>
    <path
        d="M21.8991 42.1387L21.645 44.9649C21.5973 45.4835 21.645 45.8805 21.7932 46.161C21.4915 46.3832 21.1739 46.4891 20.8298 46.4891C20.4857 46.4891 20.2581 46.4097 20.1469 46.2456C20.0358 46.0816 19.9934 45.8328 20.0252 45.4941L20.2951 42.4668C20.3269 42.0857 20.3057 41.8105 20.2157 41.6465C20.1258 41.4824 19.9511 41.3977 19.6917 41.3977C19.21 41.3977 18.7442 41.6094 18.2995 42.0328L17.9078 46.3938C17.6643 46.4362 17.4049 46.4573 17.1243 46.4573C16.8438 46.4573 16.5791 46.4362 16.3303 46.3938L16.8967 40.0958L16.992 40.0217H17.5796C18.0613 40.0217 18.326 40.2757 18.3789 40.7838C19.0459 40.2387 19.6864 39.9688 20.3004 39.9688C20.9145 39.9688 21.3538 40.1699 21.6079 40.5668C21.862 40.9637 21.9626 41.4877 21.9044 42.1334L21.8991 42.1387Z"
        fill="#2A2E53"/>
    <path
        d="M25.2924 46.5791C24.5778 46.5791 24.0537 46.2828 23.7255 45.6953C23.3973 45.1078 23.2756 44.2981 23.3708 43.2713C23.4608 42.2446 23.7784 41.4401 24.3184 40.8527C24.8583 40.2652 25.4724 39.9741 26.1605 39.9741C26.5946 39.9741 27.0286 40.0958 27.4627 40.3287L27.7486 37.1215L27.8333 37.0315H28.442C28.7543 37.0315 28.9714 37.1056 29.0825 37.2538C29.199 37.402 29.236 37.656 29.2043 38.0106L28.6167 44.5892C28.5691 45.1131 28.6537 45.4783 28.8761 45.6847C28.8231 45.8276 28.7332 45.9811 28.6114 46.1346C28.4897 46.2933 28.3732 46.4098 28.2567 46.4945C27.7274 46.4204 27.3727 46.1663 27.1927 45.7323C26.6422 46.2933 26.007 46.5738 25.2924 46.5738V46.5791ZM25.8111 45.2825C26.277 45.2825 26.7004 45.0549 27.0816 44.5945L27.3515 41.5936C26.9439 41.329 26.5787 41.202 26.2558 41.202C25.9329 41.202 25.6576 41.3872 25.4247 41.7577C25.1918 42.1282 25.0436 42.6468 24.9854 43.3137C24.9271 43.9858 24.9748 44.478 25.1336 44.8009C25.2924 45.1237 25.52 45.2825 25.8164 45.2825H25.8111Z"
        fill="#2A2E53"/>
    <path
        d="M35.6781 45.6848C35.6252 45.8277 35.5352 45.9812 35.4134 46.1347C35.2917 46.2934 35.1752 46.4099 35.0588 46.4946C34.54 46.4363 34.1747 46.1876 33.9683 45.7589C33.3595 46.304 32.7296 46.5739 32.0732 46.5739C31.4168 46.5739 30.9457 46.3834 30.6493 46.0024C30.3528 45.6213 30.2258 45.182 30.2734 44.6792C30.337 43.9647 30.6175 43.4302 31.1098 43.0703C31.6021 42.7104 32.2108 42.5305 32.9308 42.5305C33.3225 42.5305 33.6877 42.5464 34.0318 42.5834L34.0636 42.2023C34.1218 41.5302 33.8307 41.1968 33.1849 41.1968C32.7243 41.1968 32.0626 41.3503 31.1998 41.6519C30.9775 41.3873 30.8716 41.0115 30.8822 40.5193C31.835 40.1383 32.7667 39.9478 33.6824 39.9478C34.3177 39.9478 34.8205 40.1277 35.1858 40.4823C35.551 40.8369 35.7046 41.3767 35.641 42.0965L35.4081 44.6898C35.3658 45.1556 35.4558 45.489 35.6728 45.6795L35.6781 45.6848ZM32.5126 45.3673C32.9519 45.3673 33.3966 45.1661 33.8412 44.7586L33.9418 43.626C33.6454 43.5943 33.3278 43.5731 32.9784 43.5731C32.629 43.5731 32.3591 43.6631 32.1632 43.8377C31.9673 44.0177 31.8615 44.24 31.835 44.5099C31.8085 44.7798 31.8615 44.9915 31.9832 45.1397C32.105 45.2879 32.2797 45.362 32.5126 45.362V45.3673Z"
        fill="#2A2E53"/>
    <path
        d="M41.1144 40.0747C41.1409 40.2441 41.1462 40.4346 41.125 40.6516C41.1038 40.8686 41.0668 41.0591 41.0086 41.2179L39.4576 41.1915L39.1452 44.6527C39.1135 45.0338 39.2776 45.2243 39.6428 45.2243H40.458C40.5216 45.4784 40.5427 45.7059 40.5268 45.8965C40.511 46.087 40.4845 46.2405 40.4474 46.341C39.9075 46.4151 39.3993 46.4575 38.9229 46.4575C37.9383 46.4575 37.4937 45.96 37.5784 44.9703L37.9172 41.2073L36.9643 41.2179C36.9379 41.0591 36.9326 40.8686 36.9537 40.6516C36.9749 40.4346 37.012 40.2441 37.0702 40.0747L38.023 40.1012L38.1077 39.1327C38.1395 38.7781 38.2242 38.524 38.3671 38.3759C38.51 38.2277 38.7324 38.1536 39.05 38.1536H39.6587L39.7275 38.2435L39.5634 40.1012L41.1197 40.0747H41.1144Z"
        fill="#2A2E53"/>
    <path
        d="M44.6982 41.3236L44.243 46.3991C43.9995 46.4414 43.7401 46.4626 43.4595 46.4626C43.179 46.4626 42.9143 46.4414 42.6655 46.3991L43.0678 41.9111C43.1102 41.4506 42.9408 41.2231 42.5543 41.2231H42.4008C42.3691 41.0802 42.3691 40.8949 42.3849 40.6779C42.4061 40.4556 42.4432 40.2651 42.5014 40.1063C42.8084 40.0746 43.1207 40.0534 43.4331 40.0534H43.6342C43.9995 40.0534 44.2747 40.1699 44.46 40.3974C44.6453 40.625 44.7247 40.9373 44.6876 41.3236H44.6982ZM43.0678 38.942C42.999 38.7462 42.9778 38.5398 42.999 38.3122C43.0202 38.0899 43.0784 37.8782 43.1843 37.6824C43.4172 37.5659 43.6924 37.5024 44.0206 37.5024C44.3488 37.5024 44.6029 37.5607 44.7882 37.6824C44.857 37.8782 44.8782 38.0899 44.857 38.3122C44.8358 38.5345 44.7776 38.7462 44.6717 38.942C44.4653 39.0637 44.1847 39.1219 43.8354 39.1219C43.486 39.1219 43.2266 39.0637 43.0678 38.942Z"
        fill="#2A2E53"/>
    <path
        d="M51.5851 43.2449C51.4845 44.3828 51.1034 45.2508 50.447 45.8541C49.9177 46.3357 49.2613 46.5792 48.4672 46.5792C47.6732 46.5792 47.0592 46.3357 46.6198 45.8541C46.0693 45.2508 45.847 44.3828 45.9475 43.2449C46.0481 42.107 46.4293 41.2391 47.0856 40.6357C47.6097 40.1541 48.2714 39.9106 49.0654 39.9106C49.8594 39.9106 50.4735 40.1541 50.9128 40.6357C51.4634 41.2391 51.6857 42.107 51.5851 43.2449ZM48.9595 41.1067C48.1602 41.1067 47.6944 41.8212 47.5674 43.2449C47.4403 44.6686 47.7791 45.3831 48.5784 45.3831C49.3777 45.3831 49.8436 44.6686 49.9706 43.2449C50.0976 41.8212 49.7589 41.1067 48.9595 41.1067Z"
        fill="#2A2E53"/>
    <path
        d="M58.3712 42.1387L58.1172 44.9649C58.0695 45.4835 58.1172 45.8805 58.2654 46.161C57.9636 46.3832 57.646 46.4891 57.302 46.4891C56.9579 46.4891 56.7303 46.4097 56.6191 46.2456C56.5079 46.0816 56.4656 45.8328 56.4974 45.4941L56.7673 42.4668C56.7991 42.0857 56.7779 41.8105 56.6879 41.6465C56.5979 41.4824 56.4232 41.3977 56.1639 41.3977C55.6822 41.3977 55.2163 41.6094 54.7717 42.0328L54.38 46.3938C54.1365 46.4362 53.8771 46.4573 53.5965 46.4573C53.316 46.4573 53.0513 46.4362 52.8025 46.3938L53.3689 40.0958L53.4642 40.0217H54.0518C54.5335 40.0217 54.7981 40.2757 54.8511 40.7838C55.5181 40.2387 56.1586 39.9688 56.7726 39.9688C57.3867 39.9688 57.826 40.1699 58.0801 40.5668C58.3342 40.9637 58.4348 41.4877 58.3765 42.1334L58.3712 42.1387Z"
        fill="#2A2E53"/>
    <path
        d="M64.8612 46.5791C64.1466 46.5791 63.6225 46.2828 63.2944 45.6953C62.9662 45.1078 62.8444 44.2981 62.9397 43.2713C63.0297 42.2446 63.3473 41.4401 63.8872 40.8527C64.4272 40.2652 65.0412 39.9741 65.7294 39.9741C66.1634 39.9741 66.5975 40.0958 67.0316 40.3287L67.3174 37.1215L67.4021 37.0315H68.0109C68.3232 37.0315 68.5402 37.1056 68.6514 37.2538C68.7678 37.402 68.8049 37.656 68.7731 38.0106L68.1855 44.5892C68.1379 45.1131 68.2226 45.4783 68.4449 45.6847C68.392 45.8276 68.302 45.9811 68.1802 46.1346C68.0585 46.2933 67.942 46.4098 67.8256 46.4945C67.2962 46.4204 66.9416 46.1663 66.7616 45.7323C66.2111 46.2933 65.5758 46.5738 64.8612 46.5738V46.5791ZM65.38 45.2825C65.8458 45.2825 66.2693 45.0549 66.6504 44.5945L66.9204 41.5936C66.5128 41.329 66.1475 41.202 65.8246 41.202C65.5017 41.202 65.2265 41.3872 64.9936 41.7577C64.7607 42.1282 64.6124 42.6468 64.5542 43.3137C64.496 43.9858 64.5436 44.478 64.7024 44.8009C64.8612 45.1237 65.0888 45.2825 65.3853 45.2825H65.38Z"
        fill="#2A2E53"/>
    <path
        d="M70.2287 44.3615L70.4828 41.5247C70.5305 41.0166 70.4828 40.6197 70.3452 40.3445C70.6205 40.1169 70.9328 40.0005 71.2874 40.0005C71.6421 40.0005 71.8697 40.0799 71.9862 40.2439C72.0973 40.408 72.1397 40.6568 72.1079 40.9955L71.838 44.0228C71.8009 44.4144 71.8274 44.6896 71.9174 44.8537C72.0073 45.0178 72.182 45.1024 72.4414 45.1024C72.9231 45.1024 73.3889 44.8907 73.8336 44.4673L74.2253 40.0905C74.4688 40.0481 74.7335 40.0269 75.0193 40.0269C75.3052 40.0269 75.5699 40.0481 75.8028 40.0905L75.4005 44.6049C75.3528 45.1501 75.4375 45.51 75.6599 45.7005C75.554 46.0286 75.3528 46.2985 75.0511 46.4997C74.4582 46.4256 74.0718 46.1239 73.8971 45.5946C73.1878 46.2033 72.4785 46.5102 71.7691 46.5102C71.2027 46.5102 70.7898 46.3144 70.5305 45.9175C70.2711 45.5206 70.1758 45.0019 70.234 44.3615H70.2287Z"
        fill="#2A2E53"/>
    <path
        d="M0 82.5522C0.381132 80.6099 1.36572 79.0275 2.94318 77.8102C6.19868 79.9854 9.42242 81.0704 12.6197 81.0704C14.3295 81.0704 15.6952 80.6946 16.7116 79.9325C17.7279 79.1756 18.2837 78.1859 18.379 76.9687C18.596 75.0263 17.405 73.4598 14.7953 72.2742L9.15775 69.8027C4.73768 67.7598 2.6838 64.8277 2.98024 61.0066C3.20256 58.1751 4.4783 55.8199 6.79685 53.9411C9.12069 52.0623 12.0321 51.1255 15.5417 51.1255C19.0513 51.1255 22.5132 52.0146 25.9328 53.7929C25.6787 55.7352 24.8265 57.3494 23.3761 58.6355C20.3376 57.0531 17.5162 56.2645 14.9171 56.2645C13.3026 56.2645 12.0109 56.6508 11.0475 57.4235C10.0788 58.1962 9.55476 59.1277 9.47006 60.2127C9.34302 61.8269 10.4388 63.1447 12.7679 64.1662L18.4055 66.6378C23.1802 68.7812 25.4035 71.9249 25.0753 76.0743C24.8371 79.1068 23.5719 81.5573 21.2851 83.4361C18.9931 85.3149 15.7534 86.2517 11.5504 86.2517C7.34737 86.2517 3.50429 85.0185 0.010587 82.547L0 82.5522Z"
        fill="#2A2E53"/>
    <path
        d="M50.807 73.3168C50.4577 77.7307 49.0231 81.1073 46.5034 83.4466C44.476 85.3254 41.9246 86.2622 38.8437 86.2622C35.7629 86.2622 33.3597 85.3254 31.6287 83.4466C29.4743 81.1073 28.5691 77.7307 28.9184 73.3168C29.2678 68.9028 30.7023 65.5262 33.2221 63.1869C35.2495 61.3081 37.8009 60.3713 40.8817 60.3713C43.9626 60.3713 46.3658 61.3081 48.0968 63.1869C50.2512 65.5262 51.1564 68.9028 50.807 73.3168ZM40.5165 65.0181C37.4039 65.0181 35.6306 67.7861 35.1912 73.3168C34.7572 78.8474 36.0964 81.6154 39.209 81.6154C42.3216 81.6154 44.0949 78.8474 44.5342 73.3168C44.9683 67.7861 43.6291 65.0181 40.5165 65.0181Z"
        fill="#213871"/>
    <path
        d="M54.6817 77.916L55.5657 66.7277C55.7192 64.7536 55.5286 63.2188 54.9887 62.1338C56.0474 61.2447 57.2596 60.8001 58.6253 60.8001C59.9911 60.8001 60.8962 61.1124 61.3409 61.7369C61.7856 62.3614 61.9549 63.3352 61.8544 64.6531L60.9121 76.5823C60.7904 78.096 60.9068 79.1756 61.2509 79.816C61.595 80.4564 62.2779 80.7793 63.2995 80.7793C65.1787 80.7793 66.9732 79.9589 68.683 78.3077L70.0487 61.0383C70.9857 60.8742 72.0126 60.7896 73.1295 60.7896C74.2464 60.7896 75.2628 60.8742 76.1733 61.0383L74.7652 78.911C74.6011 81.0174 74.9505 82.4358 75.8133 83.1609C75.4163 84.447 74.6435 85.4843 73.4948 86.2729C71.1762 85.9765 69.6729 84.7486 68.9794 82.7057C66.2533 85.0768 63.5112 86.2623 60.7427 86.2623C58.5353 86.2623 56.9261 85.4949 55.9098 83.9653C54.8934 82.4358 54.4858 80.4194 54.6817 77.916Z"
        fill="#005CA9"/>
    <path
        d="M150.478 75.2908H136.641C136.588 77.2332 136.906 78.7415 137.594 79.8106C138.282 80.8797 139.6 81.4142 141.543 81.4142C143.486 81.4142 145.688 80.8374 148.154 79.6836C149.007 80.5727 149.499 81.8271 149.637 83.436C146.852 85.3148 143.607 86.2516 139.902 86.2516C136.196 86.2516 133.629 85.0502 132.205 82.6474C130.781 80.2446 130.22 77.1326 130.522 73.3115C130.823 69.4903 131.956 66.3783 133.925 63.9755C135.895 61.5727 138.531 60.3713 141.845 60.3713C145.158 60.3713 147.62 61.3663 149.245 63.3616C150.865 65.3569 151.558 67.8655 151.32 70.8928C151.198 72.4064 150.918 73.8725 150.478 75.2908ZM141.765 64.817C139.065 64.817 137.456 66.8599 136.932 70.9404H145.529L145.576 70.3477C145.709 68.7017 145.465 67.368 144.857 66.3466C144.248 65.3251 143.216 64.817 141.765 64.817Z"
        fill="#139259"/>
    <path
        d="M4.97599 109.623C4.08669 109.623 3.32972 109.443 2.92212 109.205V102.071C3.36677 101.822 4.0761 101.642 4.92306 101.642C7.06693 101.642 8.1468 103.151 8.15209 105.553C8.15209 107.946 7.17279 109.623 4.97599 109.623ZM4.90189 102.309C4.49429 102.309 4.21373 102.357 3.94376 102.468V108.75C4.17668 108.84 4.52605 108.898 4.91777 108.898C6.48464 108.898 7.08281 107.734 7.07751 105.548C7.07751 103.415 6.37348 102.314 4.89659 102.314L4.90189 102.309Z"
        fill="#2A2E53"/>
    <path
        d="M12.1592 109.618C10.5023 109.618 9.7771 108.258 9.7771 106.474C9.7771 104.659 10.5076 103.32 12.1433 103.315C13.8107 103.315 14.5254 104.654 14.5254 106.469C14.5254 108.253 13.8054 109.613 12.1592 109.618ZM12.1592 103.95C10.9999 103.95 10.7723 105.246 10.7723 106.474C10.7723 107.702 11.0211 108.93 12.1592 108.93C13.3184 108.93 13.5355 107.686 13.5302 106.469C13.5302 105.252 13.3132 103.944 12.1539 103.944L12.1592 103.95Z"
        fill="#2A2E53"/>
    <path
        d="M19.7607 109.496V105.135C19.7607 104.569 19.5172 103.95 18.4796 103.95C17.9185 103.95 17.6433 104.029 17.3309 104.167V109.502H16.3411V103.743C16.8757 103.484 17.5903 103.315 18.559 103.315C20.2477 103.315 20.7453 104.246 20.7453 105.114V109.502H19.7554L19.7607 109.496Z"
        fill="#2A2E53"/>
    <path
        d="M26.2187 109.491V105.13C26.2187 104.564 25.9752 103.944 24.9376 103.944C24.3765 103.944 24.1013 104.024 23.789 104.161V109.496H22.7991V103.738C23.3337 103.479 24.0483 103.309 25.017 103.309C26.711 103.309 27.2033 104.241 27.2086 105.109V109.496H26.2187V109.491Z"
        fill="#2A2E53"/>
    <path
        d="M29.9614 106.829C30.0778 108.205 30.7183 108.882 31.8141 108.877C32.5022 108.877 32.9998 108.708 33.3121 108.528L33.5715 109.184C33.201 109.412 32.5552 109.602 31.7347 109.602C30.1466 109.602 29.0297 108.602 29.0244 106.437C29.0244 104.373 30.0355 103.304 31.47 103.299C32.9257 103.299 33.8044 104.246 33.7833 106.273L29.9614 106.829ZM31.4382 103.939C30.5595 103.939 29.9031 104.648 29.9296 106.294L32.8357 105.855C32.8357 104.479 32.3064 103.939 31.4382 103.939Z"
        fill="#2A2E53"/>
    <path d="M35.1384 109.486L37.6952 104.029H35.329V103.41H38.9603L36.4618 108.866H39.0556V109.486H35.1437H35.1384Z"
          fill="#2A2E53"/>
    <path
        d="M40.6752 111.386H40.0876L41.0458 108.602C41.194 108.432 41.3951 108.321 41.7127 108.321C42.0039 108.321 42.1203 108.454 42.1415 108.602L40.6805 111.386H40.6752Z"
        fill="#2A2E53"/>
    <path
        d="M50.4366 109.586C48.5786 109.586 47.5251 107.797 47.5251 105.596C47.5251 103.442 48.5574 101.605 50.4313 101.6C51.1194 101.6 51.6064 101.78 51.9452 102.029L51.6964 102.637C51.4582 102.489 51.0983 102.336 50.6113 102.336C49.1238 102.336 48.605 103.95 48.605 105.596C48.605 107.273 49.182 108.808 50.6007 108.808C51.0771 108.808 51.3894 108.681 51.6805 108.501L51.9505 109.152C51.6117 109.412 51.0612 109.581 50.426 109.581L50.4366 109.586Z"
        fill="#2A2E53"/>
    <path
        d="M56.9792 109.47V105.162C56.9792 104.532 56.7039 103.966 55.7352 103.966C55.2853 103.966 54.9253 104.045 54.5495 104.273V109.47H53.5596V100.621H54.5442V103.712C55.0523 103.452 55.4917 103.341 55.9469 103.336C57.4926 103.336 57.9638 104.267 57.9691 105.125V109.465H56.9792V109.47Z"
        fill="#2A2E53"/>
    <path
        d="M60.7165 106.808C60.8329 108.184 61.4735 108.861 62.5692 108.856C63.2574 108.856 63.755 108.687 64.0673 108.507L64.3267 109.163C63.9561 109.39 63.3103 109.581 62.4898 109.581C60.9018 109.581 59.7848 108.581 59.7795 106.416C59.7795 104.352 60.7906 103.283 62.2251 103.278C63.6809 103.278 64.5596 104.225 64.5384 106.252L60.7165 106.808ZM62.1934 103.918C61.3147 103.918 60.6583 104.627 60.6847 106.278L63.5962 105.834C63.5962 104.458 63.0668 103.918 62.1987 103.918H62.1934Z"
        fill="#2A2E53"/>
    <path
        d="M68.7308 104.034C68.2332 103.886 67.6721 103.934 67.3439 104.145V109.454H66.354V103.706C66.9469 103.399 67.5239 103.24 68.9637 103.278L68.7255 104.034H68.7308Z"
        fill="#2A2E53"/>
    <path
        d="M72.5157 109.57C71.187 109.57 70.2024 108.38 70.2024 106.416C70.2024 104.421 71.1817 103.267 72.5368 103.267C73.2832 103.267 73.6855 103.505 73.8814 103.669L73.6432 104.246C73.4632 104.109 73.1932 103.955 72.7433 103.955C71.6369 103.955 71.2082 104.961 71.2135 106.379C71.2135 107.835 71.7322 108.835 72.7168 108.835C73.135 108.835 73.4155 108.697 73.6432 108.544L73.892 109.163C73.6538 109.38 73.2038 109.57 72.5262 109.57H72.5157Z"
        fill="#2A2E53"/>
    <path
        d="M78.9421 109.449V105.141C78.9421 104.511 78.6668 103.944 77.6981 103.944C77.2481 103.944 76.8882 104.024 76.5123 104.251V109.449H75.5225V100.6H76.5071V103.69C77.0152 103.431 77.4546 103.32 77.9098 103.315C79.4555 103.315 79.9267 104.246 79.9319 105.104V109.443H78.9421V109.449Z"
        fill="#2A2E53"/>
    <path
        d="M84.1296 109.56C82.4728 109.56 81.7476 108.2 81.7476 106.416C81.7476 104.601 82.4781 103.262 84.1138 103.256C85.7812 103.256 86.4958 104.595 86.4958 106.411C86.4958 108.194 85.7759 109.554 84.1296 109.56ZM84.1296 103.891C82.9704 103.891 82.7427 105.188 82.7427 106.416C82.7427 107.644 82.9915 108.872 84.1296 108.872C85.2889 108.872 85.5059 107.628 85.5007 106.411C85.5007 105.193 85.2836 103.886 84.1243 103.886L84.1296 103.891Z"
        fill="#2A2E53"/>
    <path
        d="M91.7257 109.443V105.082C91.7257 104.516 91.4822 103.897 90.4447 103.897C89.8836 103.897 89.6083 103.976 89.296 104.114V109.449H88.3062V103.69C88.8408 103.431 89.5554 103.262 90.5241 103.262C92.2127 103.262 92.7103 104.193 92.7103 105.066V109.454H91.7205L91.7257 109.443Z"
        fill="#2A2E53"/>
    <path
        d="M95.9552 109.528C95.2988 109.528 94.7271 109.359 94.3989 109.11L94.6689 108.454C94.8806 108.634 95.3094 108.814 95.8546 108.814C96.5322 108.814 96.9822 108.348 96.9822 107.766C96.9822 107.136 96.6434 106.855 95.9764 106.607C94.7906 106.167 94.5524 105.538 94.5524 104.908C94.5524 104.008 95.2618 103.241 96.3681 103.235C96.9874 103.235 97.4744 103.436 97.7868 103.653L97.5274 104.252C97.3474 104.119 96.9874 103.913 96.511 103.913C95.8441 103.913 95.4947 104.352 95.4947 104.871C95.4947 105.458 95.9235 105.739 96.511 105.961C97.6968 106.411 97.9879 107.041 97.9879 107.739C97.9879 108.665 97.1886 109.523 95.9499 109.523L95.9552 109.528Z"
        fill="#2A2E53"/>
    <path
        d="M99.6977 111.338H99.1101L100.068 108.554C100.216 108.385 100.418 108.274 100.735 108.274C101.026 108.274 101.143 108.406 101.164 108.554L99.703 111.338H99.6977Z"
        fill="#2A2E53"/>
    <path
        d="M135.476 109.507L132.925 105.892H132.904V109.512H131.882V102.06C132.284 101.833 132.967 101.642 133.804 101.642C135.561 101.642 136.466 102.494 136.466 103.939C136.466 105.257 135.529 106.051 134.111 106.072V106.093L136.652 109.507H135.476ZM133.777 102.251C133.396 102.251 133.1 102.309 132.898 102.42V105.664C133.015 105.696 133.295 105.733 133.565 105.733C134.831 105.733 135.439 105.098 135.434 103.987C135.434 102.801 134.915 102.251 133.777 102.251Z"
        fill="#2A2E53"/>
    <path
        d="M139.049 106.839C139.161 108.215 139.806 108.893 140.902 108.893C141.59 108.893 142.088 108.723 142.4 108.543L142.66 109.2C142.289 109.427 141.643 109.618 140.823 109.618C139.235 109.618 138.118 108.618 138.113 106.453C138.113 104.389 139.124 103.32 140.558 103.314C142.014 103.314 142.893 104.262 142.871 106.289L139.049 106.845V106.839ZM140.526 103.95C139.648 103.95 138.991 104.659 139.018 106.305L141.929 105.865C141.929 104.489 141.4 103.95 140.532 103.95H140.526Z"
        fill="#2A2E53"/>
    <path
        d="M145.873 109.586C145.216 109.586 144.645 109.417 144.316 109.168L144.586 108.512C144.798 108.692 145.227 108.872 145.772 108.872C146.45 108.872 146.9 108.406 146.9 107.824C146.9 107.194 146.561 106.914 145.894 106.665C144.708 106.225 144.47 105.596 144.47 104.966C144.47 104.066 145.179 103.299 146.286 103.293C146.905 103.293 147.392 103.495 147.704 103.712L147.445 104.31C147.265 104.177 146.905 103.971 146.429 103.971C145.762 103.971 145.412 104.41 145.412 104.929C145.412 105.516 145.841 105.797 146.429 106.019C147.614 106.469 147.905 107.099 147.905 107.797C147.905 108.724 147.106 109.581 145.867 109.581L145.873 109.586Z"
        fill="#2A2E53"/>
    <path
        d="M151.622 109.581C151.272 109.581 150.902 109.512 150.674 109.433V111.915H149.695V103.733C150.092 103.494 150.716 103.304 151.585 103.304C153.3 103.304 154.268 104.495 154.268 106.358C154.268 108.374 153.22 109.581 151.622 109.586V109.581ZM151.537 103.897C151.166 103.897 150.907 103.966 150.669 104.066V108.835C150.865 108.914 151.113 108.972 151.415 108.967C152.643 108.967 153.273 108.009 153.273 106.395C153.273 104.849 152.818 103.891 151.537 103.891V103.897Z"
        fill="#2A2E53"/>
    <path
        d="M156.645 102.24C156.317 102.24 156.057 101.991 156.057 101.7C156.057 101.393 156.317 101.15 156.645 101.15C156.963 101.15 157.222 101.399 157.222 101.7C157.222 101.991 156.963 102.24 156.645 102.24ZM156.153 109.491V103.415H157.143V109.491H156.153Z"
        fill="#2A2E53"/>
    <path
        d="M161.626 104.061C161.129 103.913 160.568 103.96 160.239 104.177V109.486H159.25V103.738C159.842 103.431 160.419 103.272 161.859 103.309L161.621 104.066L161.626 104.061Z"
        fill="#2A2E53"/>
    <path
        d="M164.035 106.823C164.146 108.2 164.792 108.877 165.888 108.872C166.576 108.872 167.073 108.702 167.386 108.522L167.645 109.173C167.274 109.401 166.629 109.591 165.808 109.591C164.22 109.591 163.103 108.591 163.098 106.427C163.098 104.362 164.109 103.293 165.544 103.288C166.999 103.288 167.878 104.235 167.857 106.262L164.035 106.818V106.823ZM165.512 103.934C164.633 103.934 163.977 104.643 164.003 106.294L166.915 105.85C166.915 104.474 166.385 103.934 165.517 103.934H165.512Z"
        fill="#2A2E53"/>
    <path d="M169.212 109.48L171.769 104.024H169.402V103.405H173.034L170.535 108.861H173.129V109.48H169.217H169.212Z"
          fill="#2A2E53"/>
    <path
        d="M179.344 109.528C178.994 109.528 178.703 109.237 178.698 108.877C178.698 108.528 178.989 108.237 179.338 108.237C179.698 108.237 179.995 108.528 179.995 108.877C179.995 109.237 179.704 109.533 179.344 109.533V109.528ZM179.714 106.924H178.968L178.751 101.716H179.926L179.714 106.924Z"
        fill="#2A2E53"/>
  </svg>
</template>

<script>
export default {
  name: 'Logo'
}
</script>

<style lang="scss" scoped>
svg {
  display: block;
  max-width: 100%;
  width: 100%;
  height: auto;
}
</style>
