<template>
  <section class="heading">
    <div class="layout-content heading-wrapper">
      <div class="heading-content">
        <h1 class="heading-title">
          <span class="value" v-html="value"></span>
          <span class="text" v-html="title"></span>
        </h1>
        <p class="heading-info" v-html="info"></p>
        <img :src="require(`@/assets/images/shape.svg`)" alt="" />
      </div>

      <div class="heading-form">
        <FormGift/>
      </div>
    </div>
  </section>
</template>

<script>
import data from '@/data/content.json'
import FormGift from "@/components/ui/FormGift.vue";

export default {
  name: 'Heading',
  components: {
    FormGift
  },
  data() {
    return {
      value: data.heading.value,
      title: data.heading.title,
      info: data.heading.info
    }
  }
}
</script>

<style lang="scss" scoped>
.heading {
  display: flex;
  min-height: 81rem;
  background-image: url('@/assets/images/bg-heading.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 35% 50%;

  @include respond-to('medium up') {
    background-position: center;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $gutter-D;
    width: 100%;

    @include respond-to('medium up') {
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
    }
  }

  &-content {
    position: relative;
    max-width: 42rem;
    margin: 30rem 0 0;

    @include respond-to('medium up') {
      max-width: 45rem;
      margin: 0 0 18rem;
      flex-shrink: 0;
    }

    @include respond-to('large up') {
      max-width: 61rem;
    }

    img {
      @include absolute(bottom -3rem right -3rem);

      pointer-events: none;

      @include respond-to('medium') {
        display: none;
      }
    }
  }

  &-title {
    display: flex;
    flex-direction: column;
    margin-bottom: $gutter-B;
    color: $c-white;
    text-shadow: 0 3px 4px $c-black;
    line-height: 1.25;

    .value {
      font-size: 3.8rem;

      @include respond-to('medium up') {
        font-size: $fs-title-B;
      }

      @include respond-to('large up') {
        font-size: $fs-title-A;
      }
    }

    .text {
      font-size: $fs-title-F;

      @include respond-to('medium up') {
        font-size: $fs-title-E;
      }

      @include respond-to('large up') {
        font-size: $fs-title-D;
      }
    }
  }

  &-info {
    color: $c-white;
    font-size: $fs-txt-D;
    font-weight: 500;
    font-style: italic;
    text-shadow: 0 3px 4px $c-black;

    @include respond-to('medium up') {
      font-size: $fs-txt-B;
    }

    @include respond-to('large up') {
      font-size: $fs-txt-A;
    }
  }

  &-form {
    position: relative;
    z-index: 2;
    margin-bottom: 6rem;

    @include respond-to('xs up') {
      margin-bottom: 10rem;
    }
  }
}
</style>
