<template>
  <section class="gift-tax">
    <div class="gift-tax-wrapper layout-content">
      <h2 class="gift-tax-title">
        <span class="text" v-html="title"></span>
        <span class="value" v-html="percentage"></span>
      </h2>

      <div class="gift-tax-content">
        <div class="gift-tax-media">
          <div class="gift-tax-illustration">
            <p class="gift-tax-gift">
              <span class="text" v-html="gift"></span>
              <span class="value" v-html="gift_value"></span>
            </p>

            <p class="gift-tax-deduction">
              <span class="text" v-html="deduction"></span>
              <span class="value" v-html="deduction_value"></span>
            </p>

            <p class="gift-tax-cost">
              <strong class="value" v-html="cost_value"></strong>
              <span class="text" v-html="cost"></span>
            </p>
          </div>

          <p class="gift-tax-percentage">
            <strong class="value" v-html="tax_percentage"></strong>
            <span class="text" v-html="tax_text"></span>
          </p>
        </div>

        <div class="gift-tax-text">
          <p class="gift-tax-content" v-html="text_1"></p>
          <p class="gift-tax-content" v-html="text_2"></p>
          <BtnGift id="cta-gift-tax" link="https://soutenir-lesouffle.iraiser.eu/lesouffleCFA2023/~mon-don"/>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import data from '@/data/content.json'
import BtnGift from "@/components/ui/BtnGift.vue";

export default {
  name: 'GiftTax',
  components: {BtnGift},
  data() {
    return {
      title: data.gift_tax.title,
      percentage: data.gift_tax.percentage,
      text_1: data.gift_tax.text_1,
      text_2: data.gift_tax.text_2,
      gift: data.gift_tax.gift,
      gift_value: data.gift_tax.gift_value,
      deduction: data.gift_tax.deduction,
      deduction_value: data.gift_tax.deduction_value,
      cost: data.gift_tax.cost,
      cost_value: data.gift_tax.cost_value,
      tax_percentage: data.gift_tax.tax_percentage,
      tax_text: data.gift_tax.tax_text
    }
  }
}
</script>

<style lang="scss" scoped>
.gift-tax {
  padding: $gutter-J 0;

  &-title {
    display: flex;
    flex-direction: column;
    gap: $gutter-A;
    margin-bottom: $gutter-F;

    @include respond-to('medium up') {
      flex-direction: row;
      align-items: center;
    }

    .text {
      max-width: 52rem;
      margin: 0;
      color: $c-bg-E;
      font-size: $fs-title-D;
      font-weight: 400;
      line-height: 1.4;

      @include respond-to('medium up') {
        font-size: $fs-title-B;
      }
    }

    .value {
      margin-top: -$gutter-B;
      color: $c-bg-E;
      font-size: $fs-title-A;
      font-weight: 700;

      @include respond-to('medium up') {
        margin-top: $gutter-F;
        font-size: 10rem;
      }
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include respond-to('medium up') {
      flex-direction: row;
    }
  }

  &-media {
    flex-grow: 1;
    max-width: 33.5rem;
    margin-bottom: $gutter-E;

    @include respond-to('small up') {
      max-width: 55rem;
    }

    @include respond-to('medium up') {
      flex-shrink: 0;
      max-width: 45rem;
    }

    @include respond-to('large up') {
      max-width: 60rem;
    }

    @include respond-to('large') {
      margin: 0 auto $gutter-E;
    }
  }

  &-illustration {
    position: relative;
    min-height: 17.2rem;
    margin-bottom: $gutter-E;
    background-image: url('@/assets/images/line-dotted.svg');
    background-repeat: no-repeat;
    background-size: 12.4rem 14.3rem;
    background-position: 11rem 2.8rem;

    @include respond-to('small up') {
      min-height: 22rem;
      margin-bottom: $gutter-J;
      background-size: 17rem;
      background-position: 15rem 2.5rem;
    }

    @include respond-to('large up') {
      min-height: 31.5rem;
      background-size: auto;
      background-position: 20rem 5.2rem;
    }
  }

  &-gift,
  &-deduction {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: $radius-A;
    font-weight: 700;
    line-height: 1.2;
  }

  &-gift {
    @include absolute(top left);

    width: 15rem;
    height: 15rem;
    background-color: $c-bg-B;
    color: $c-secondary-A;
    font-size: $fs-title-G;

    @include respond-to('small up') {
      width: 20rem;
      height: 20rem;
      font-size: $fs-title-F;
    }

    @include respond-to('large up') {
      width: 27.5rem;
      height: 27.5rem;
      font-size: $fs-title-D;
    }

    .value {
      font-size: $fs-title-C;

      @include respond-to('small up') {
        font-size: $fs-title-B;
      }

      @include respond-to('large up') {
        font-size: 7.2rem;
      }
    }
  }

  &-deduction {
    @include absolute(top 0.5rem right);

    width: 10.6rem;
    height: 10.6rem;
    background-color: $c-bg-F;
    color: $c-primary-A;
    font-size: $fs-txt-D;

    @include respond-to('small up') {
      top: 3.2rem;
      right: 8rem;
      width: 15rem;
      height: 15rem;
      font-size: $fs-txt-B;
    }

    @include respond-to('medium up') {
      right: 0;
    }

    @include respond-to('large up') {
      width: 19.3rem;
      height: 19.3rem;
      font-size: $fs-title-G;
    }

    .value {
      font-size: $fs-title-D;

      @include respond-to('small up') {
        font-size: $fs-title-C;
      }

      @include respond-to('large up') {
        font-size: $fs-title-B;
      }
    }
  }

  &-cost {
    @include absolute(bottom 1.2rem left 14.7rem);

    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    gap: $gutter-A;
    max-width: 11rem;
    padding: 0.5rem $gutter-A;
    border-radius: $radius-D;
    background-color: $c-primary-B;
    color: $c-white;
    text-transform: uppercase;
    font-size: $fs-txt-E;

    @include respond-to('small up') {
      left: 18.7rem;
      bottom: 0;
      max-width: 12rem;
    }

    @include respond-to('large up') {
      left: 26.7rem;
      bottom: 3.8rem;
      max-width: 17rem;
      padding: $gutter-A;
      font-size: $fs-txt-D;
    }

    strong {
      font-size: $fs-title-H;

      @include respond-to('small up') {
        font-size: $fs-title-F;
      }

      @include respond-to('large up') {
        font-size: $fs-title-D;
      }
    }
  }

  &-percentage {
    display: flex;
    align-items: flex-start;
    gap: $gutter-C;
    padding: $gutter-C $gutter-D;
    border: 2px dashed $c-primary-A;
    border-radius: $radius-D;
    color: $c-primary-A;

    @include respond-to('small up') {
      align-items: center;
      gap: $gutter-H;
      padding: $gutter-C $gutter-H;
    }

    @include respond-to('medium up') {
      gap: $gutter-D;
      padding: $gutter-C;
    }

    @include respond-to('large up') {
      gap: $gutter-H;
      padding: $gutter-C $gutter-H;
    }

    .value {
      font-size: $fs-title-H;

      @include respond-to('small up') {
        font-size: $fs-title-D;
      }
    }

    .text {
      @include respond-to('small') {
        font-size: $fs-txt-D;
      }
    }
  }

  &-text {
    max-width: 60rem;
    margin: 0 auto;

    @include respond-to('medium up') {
      max-width: 100%;
      margin-left: $gutter-E;
    }

    @include respond-to('large up') {
      max-width: 48.6rem;
      margin-left: $gutter-I;
    }

    p {
      margin-bottom: $gutter-E;

      @include respond-to('small') {
        font-size: $fs-txt-B;
      }
    }
  }
}
</style>
