<template>
  <header id="header" role="banner" class="header">
    <div class="header-wrapper layout-wrapper">
      <div class="header-content layout-content">
        <div class="header-link">
          <a
              class="header-logo"
              href="https://www.lesouffle.org/"
              title="Accueil - La fondation du souffle (nouvel onglet)"
              target="_blank"
          >
            <LogoSvg/>
          </a>
        </div>
        <div class="header-btn">
          <BtnGift classes="i-a11y i-after i-inline-flex i-gift" link="https://soutenir-lesouffle.iraiser.eu/lesouffleCFA2023/~mon-don" id="cta-header"/>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import LogoSvg from '@/components/ui/Logo.vue'
import BtnGift from '@/components/ui/BtnGift.vue'

export default {
  name: 'Header',
  components: {
    LogoSvg,
    BtnGift
  },
  created() {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      const header = document.getElementById('header')

      if (window.scrollY === 0) {
        header.classList.remove('scrolling')
      } else {
        header.classList.add('scrolling')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  $this: &;
  $default: '#{$this}:not(.scrolling) &';
  $has-modifier: '#{$this}.scrolling &';

  @include fixed(left right);
  z-index: 9;

  &.scrolling {
    padding: $gutter-B 0;
    background-color: $c-white;
    box-shadow: $shadow-A;
    transition: $t-default;
  }

  &-wrapper {
    position: relative;
  }

  &-content {
    display: flex;
    justify-content: flex-end;

    #{$has-modifier} {
      justify-content: space-between;
      align-items: center;
    }
  }

  &-link {
    width: 17rem;
    height: 11rem;
    padding: $gutter-B 0 0 $gutter-B;
    background-image: url('@/assets/images/bg-logo-mobile.svg');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: bottom right;

    #{$default} {
      @include absolute(top left);
    }

    #{$has-modifier} {
      width: auto;
      height: auto;
      padding: 0;
    }

    @include respond-to('xs up') {
      width: 22rem;
      height: 15rem;
      padding: $gutter-G 0 0 $gutter-D;
      background-position: top left;
    }

    @include respond-to('medium up') {
      width: 40rem;
      height: 20rem;
      padding: $gutter-D 0 0 $gutter-G;
      background-image: url('@/assets/images/bg-logo.svg');
    }
  }

  &-logo {
    display: block;
    width: 10rem;
    transition: $t-transform;

    @include respond-to('medium up') {
      width: 18rem;

      #{$has-modifier} {
        width: 13.5rem;
      }
    }

    &:hover,
    &:focus {
      transform: scale(1.1);
    }
  }

  &-btn {
    #{$default} {
      margin-top: 5.5rem;
    }
  }
}
</style>
