<template>
  <div class="form-gift">
    <div class="form-gift-heading">
      <h2 class="form-gift-title" v-html="title"></h2>
      <p class="form-gift-subtitle" v-html="subtitle"></p>
    </div>
    <form
        method="post"
        action="https://soutenir-lesouffle.iraiser.eu/lesouffleCFA2023/"
        target="_blank"
    >
      <div class="form-gift-amounts-wrapper">
        <fieldset class="form-gift-wrapper">
          <legend v-html="legend" class="visually-hidden"></legend>
          <div class="form-gift-field form-gift-amounts">
            <div
                v-for="(amount, index) in amounts"
                :key="index"
                :class="'form-gift-amount-' + index"
            >
              <input
                  type="radio"
                  name="amounts"
                  class="visually-hidden"
                  :id="'cta-form-amount-' + amount"
                  :value="amount"
                  :checked="index === amounts.indexOf(amountSelected)"
              />
              <label :for="'cta-form-amount-' + amount" @click="radioCheck">
                {{ amount }} €
              </label>
            </div>
          </div>
        </fieldset>

        <div class="form-gift-field form-gift-free-amount">
          <label for="free-amount" class="default">Montant libre</label>
          <input
              id="free-amount"
              type="number"
              @focus="inputFocused"
              @blur="inputLeave"
              v-model="customAmount"
          />
        </div>
      </div>

      <div class="form-gift-wrapper">
        <p class="form-gift-reduction">
          <span class="form-gift-reduction-prefix">{{ reducePrefix }} </span>
          <span class="form-gift-reduction-amount">{{ getReduceAmount }} €</span>
          <span class="form-gift-reduction-info">{{ reduceInfo }}</span>
        </p>

        <input type="hidden" name="amount" :value="calculatedAmount"/>
        <input type="hidden" name="reserved_code_media" value="">

        <button id="btn-gift-form" type="submit" class="btn-gift">
          Je fais un don <span>maintenant</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import data from '@/data/content.json'
import {taxReduction} from '@/store/store'

export default {
  name: 'FormGift',
  data() {
    return {
      title: data.form_gift.title,
      subtitle: data.form_gift.subtitle,
      legend: data.form_gift.legend,
      amounts: data.form_gift.amounts,
      reducePrefix: data.tax_reduce.prefix,
      reduceInfo: data.tax_reduce.text,
      amountSelected: 150,
      customAmount: ''
    }
  },
  computed: {
    getReduceAmount() {
      return taxReduction.getReduceAmount(this.amountSelected)
    },
    calculatedAmount() {
      return this.customAmount !== '' ? this.customAmount * 100 : this.amountSelected * 100;
    },
  },
  methods: {
    radioCheck: function (event) {
      // Clear input free amount
      const inputFreeAmount = document.getElementById('free-amount')
      const labelFreeAmount = inputFreeAmount.previousSibling
      this.customAmount = inputFreeAmount.value = ''
      labelFreeAmount.classList.remove('small')

      // Get selected radio value
      this.amountSelected = Number(event.target.previousSibling.value)
    },
    inputFocused: (event) => {
      // Add class to reduce label size
      event.target.previousSibling.classList.add('small')
    },
    inputLeave: function (event) {
      if (!event.target.value) {
        // Remove class if field is empty
        event.target.previousSibling.classList.remove('small')
      } else {
        // Uncheck radio
        const inputChecked = document.querySelector(
            'input[name="amounts"]:checked'
        )
        if (inputChecked) inputChecked.checked = false
      }
    },
  },
  watch: {
    customAmount: function (value) {
      if (value === '') {
        const selectedRadio = document.querySelector('input[name="amounts"]:checked');
        if (selectedRadio) {
          this.amountSelected = Number(selectedRadio.value);
        }
      } else {
        this.amountSelected = value;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.form-gift {
  max-width: 42.6rem;
  padding: $gutter-C;
  background-color: $c-secondary-A;
  border-radius: $radius-B;
  color: $c-white;
  text-align: center;

  @include respond-to('medium up') {
    padding: $gutter-G;
  }

  &-heading {
    padding: $gutter-B $gutter-D;
    margin-bottom: $gutter-C;
    background-color: $c-white;
    border-radius: $radius-C;

    @include respond-to('medium up') {
      margin-bottom: $gutter-E;
    }
  }

  &-title {
    margin: 0 auto $gutter-A;
    font-size: $fs-title-H;
    line-height: 1.25;

    @include respond-to('medium up') {
      font-size: $fs-title-F;
      max-width: 28rem;
    }

    @include respond-to('large up') {
      font-size: $fs-title-D;
    }
  }

  &-subtitle {
    color: $c-primary-A;
    font-size: $fs-txt-C;

    @include respond-to('large up') {
      font-size: $fs-title-H;
    }
  }

  &-amounts {
    &-wrapper {
      display: flex;
      flex-direction: column;
      gap: $gutter-A;

      .form-gift-amounts {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: $gutter-A;
      }
    }

    label {
      @include btn-color($c-secondary-B, $c-white, none);

      display: flex;
      align-items: center;
      justify-content: center;
      padding: $gutter-A 0;
      line-height: 1.55;
      transition: $t-color;

      &:hover {
        cursor: pointer;
      }
    }

    input:checked + label,
    input:focus + label {
      color: $c-secondary-B;
      background-color: $c-white;
    }
  }

  &-free-amount {
    display: flex;
    align-items: center;
    gap: $gutter-B;
    position: relative;

    label {
      @include center(y);

      margin-left: $gutter-C;
      color: $c-white;
      font-weight: 500;
      transition: $t-default;

      &.small {
        margin-top: (-$gutter-C);;
        margin-left: $gutter-B;
        font-size: $fs-txt-D;
      }
    }

    input {
      padding: $gutter-B;
      border: none;
      background-color: $c-secondary-B;
      color: $c-white;
      font-weight: bold;
    }
  }

  &-reduction {
    margin: $gutter-C 0;
    font-size: $fs-txt-B;
    font-weight: 700;

    @include respond-to('large up') {
      margin: $gutter-E 0;
      font-size: $fs-title-H;
    }

    &-info {
      font-size: $fs-txt-D;

      @include respond-to('large up') {
        font-size: $fs-txt-C;
      }
    }
  }
}

.btn-gift {
  padding: $gutter-A $gutter-C;
  background-color: $c-white;
  color: $c-secondary-B;
  border: none;
  border-radius: $radius-D;
  font-size: $fs-title-H;
  font-weight: 700;
  line-height: 1.45;
  text-transform: uppercase;
  transition: $t-default;

  &:hover,
  &:focus {
    background-color: $c-primary-B;
    color: $c-white;
  }

  span {
    text-decoration: underline;
  }
}

input[type='number'] {
  appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}
</style>
