<template>
  <section class="social-share">
    <div class="social-share-wrapper layout-content">
      <div class="social-share-media">
        <img
            :width="imgWidth"
            :height="imgHeight"
            :src="require(`@/assets/images/${img}`)"
            alt=""
        />
      </div>

      <div class="social-share-content">
        <h2 class="social-share-title" v-html="title"></h2>
        <Share/>
      </div>
    </div>
  </section>
</template>

<script>
import data from '@/data/content.json'
import Share from "@/components/ui/Share.vue";

export default {
  name: 'SocialShare',
  components: {
    Share
  },
  data() {
    return {
      title: data.social_share.title,
      img: data.social_share.image.file,
      imgWidth: data.social_share.image.width,
      imgHeight: data.social_share.image.height
    }
  }
}
</script>

<style lang="scss" scoped>
.social-share {
  position: relative;
  overflow: hidden;
  background-color: $c-bg-C;

  &-wrapper {
    display: flex;
    flex-direction: column;
    padding-bottom: $gutter-H;

    @include respond-to('medium up') {
      flex-direction: row;
      align-items: center;
      min-height: 38.8rem;
      padding-top: $gutter-H;
    }
  }

  &-content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    padding: $gutter-E $gutter-G;
    background-color: $c-white;
    border-radius: $radius-B;
    text-align: center;

    @include respond-to('medium up') {
      max-width: 45rem;
      padding: $gutter-I $gutter-E;
    }

    @include respond-to('large up') {
      max-width: 58.8rem;
    }
  }

  &-title {
    font-size: $fs-title-F;

    @include respond-to('medium') {
      font-size: $fs-txt-A;
      font-weight: 600;
    }
  }

  &-media {
    @include respond-to('medium up') {
      @include absolute(top right 6rem bottom);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}
</style>
