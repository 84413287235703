<template>
  <a
      class="btn"
      :class="classes"
      :href="link"
      :id="id"
      title="Formulaire de don Fondation du Souffle (ouverture dans un nouvel onglet)"
      target="_blank"
  >
    {{ text }}
    <span class="i-a11y-content" aria-hidden="true"></span>
  </a>
</template>

<script>
export default {
  name: 'BtnGift',
  props: {
    text: {
      type: String,
      default: 'Faire un don'
    },
    classes: {
      type: String
    },
    link: {
      type: String
    },
    id: {
      type: String
    }
  },
}
</script>

<style lang="scss" scoped>
.i-a11y-content {
  font-size: $fs-txt-C;

  @include respond-to('xs up') {
    font-size: $fs-title-F;
  }
}
</style>
