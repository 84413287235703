<template>
  <div class="item-mission">
    <div class="item-mission-media">
      <img
          width="792"
          height="511"
          :src="require(`@/assets/images/${img}`)"
          alt=""
      />
    </div>

    <div class="item-mission-wrapper">
      <h3 class="item-mission-title">
        <span v-for="(text, index) in title" :key="index">
          {{ text }}
        </span>
      </h3>

      <p class="item-mission-text" v-html="text"></p>

      <BtnGift :id="cta_id" link="https://soutenir-lesouffle.iraiser.eu/lesouffleCFA2023/~mon-don"/>
    </div>
  </div>
</template>

<script>
import BtnGift from '@/components/ui/BtnGift.vue'

export default {
  name: 'ItemMission',
  components: {
    BtnGift
  },
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      img: this.content.img,
      title: this.content.title,
      text: this.content.text,
      cta_id: this.content.id
    }
  }
}
</script>

<style lang="scss" scoped>
.item-mission {
  display: flex;
  flex-direction: column;
  width: 100%;

  @include respond-to('small up') {
    flex-direction: row;
    align-items: center;
  }

  &-media {
    flex-shrink: 0;
    border-radius: $radius-B $radius-B 0 0;
    overflow: hidden;

    @include respond-to('small up') {
      max-width: 65%;
      border-radius: 0;
    }
  }

  &-wrapper {
    flex-grow: 1;
    padding: $gutter-D;
    background-color: $c-white;
    border-radius: 0 0 $radius-B $radius-B;

    @include respond-to('small up') {
      padding: $gutter-G $gutter-D;
      margin-left: -10rem;
      border-radius: $radius-B;
    }

    @include respond-to('large up') {
      padding: $gutter-I $gutter-D;
    }
  }

  &-title {
    margin-bottom: $gutter-B;
    font-size: $fs-txt-A;

    @include respond-to('medium up') {
      margin-bottom: $gutter-D;
      font-size: $fs-title-D;
    }
  }

  &-text {
    margin-bottom: $gutter-D;
    color: $c-primary-A;

    @include respond-to('medium') {
      margin-bottom: $gutter-B;
      font-size: $fs-txt-C;
    }
  }
}
</style>
