<template>
  <section class="donation">
    <div class="donation-wrapper layout-content">
      <div class="donation-content">
        <h2 class="donation-title">
          <span v-for="(text, index) in title" :key="index">
          {{ text }}
          </span>
        </h2>
        <div class="donation-text">
          <p v-html="text"></p>
          <p class="donation-info" v-html="info"></p>
        </div>
      </div>

      <Carousel
          id="carousel-donation"
          :items-to-show="itemsToShow"
          :breakpoints="breakpoints"
          :i18n="ariaLabels"
      >
        <Slide v-for="(donation, index) in donations" :key="index">
          <ItemDonation :content="donation"/>
        </Slide>

        <template #addons>
          <Pagination/>
        </template>
      </Carousel>
    </div>
  </section>
</template>

<script>
import data from '@/data/content.json'
import ItemDonation from '@/components/ui/ItemDonation.vue'
import {Carousel, Slide, Pagination} from 'vue3-carousel'

export default {
  name: 'Donation',
  components: {
    ItemDonation,
    Carousel,
    Slide,
    Pagination
  },
  data() {
    return {
      title: data.donation.title,
      text: data.donation.text,
      info: data.donation.info,
      donations: data.donations,
      itemsToShow: 1,
      breakpoints: {
        961: {
          itemsToShow: 4,
          mouseDrag: false,
          touchDrag: false
        }
      },
      ariaLabels: data.carousel.ariaLabels
    }
  }
}
</script>

<style lang="scss" scoped>
.donation {
  padding: $gutter-H 0;
  text-align: center;

  &-content {
    position: relative;
    z-index: 0;
    padding-bottom: $gutter-I;

    &::after {
      @include absolute(top right bottom left);

      display: block;
      z-index: -1;
      content: '';
      background-image: url('@/assets/images/shape.svg');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom;
      opacity: 0.1;
    }
  }

  &-title {
    max-width: 71rem;
    margin: 0 auto $gutter-E;
    font-size: $fs-title-F;
    font-weight: 400;

    @include respond-to('medium up') {
      font-size: $fs-title-C;
      line-height: 1.2;
    }

    span:nth-of-type(2) {
      font-size: $fs-title-F;
      font-weight: 700;

      @include respond-to('medium up') {
        font-size: $fs-title-B;
      }
    }
  }

  &-text {
    max-width: 120rem;
    margin: 0 auto;
    padding: 0 $gutter-B;
    color: $c-primary-A;
    font-size: $fs-txt-B;

    @include respond-to('medium up') {
      padding: 0;
      font-size: $fs-title-F;
    }
  }

  &-info {
    margin-top: $gutter-C;
    font-size: $fs-title-H;
    font-weight: 600;

    @include respond-to('medium up') {
      margin-top: $gutter-E;
      font-size: $fs-title-D;
    }
  }
}
</style>
