<template>
  <section class="keyfigure">
    <div class="keyfigure-content layout-content">
      <Carousel
          :items-to-show="itemsToShow"
          :breakpoints="breakpoints"
          :i18n="ariaLabels"
      >
        <Slide
            class="keyfigure-item"
            v-for="(KeyFigure, index) in KeyFigures"
            :key="index"
            :class="[(index % 2 === 0) ? 'keyfigure-item-even' : 'keyfigure-item-odd']"
        >
          <ItemKeyFigure :content="KeyFigure"/>
        </Slide>

        <template #addons>
          <Pagination/>
        </template>
      </Carousel>
    </div>

    <div class="layout-content">
      <div class="keyfigure-info">
        <p v-html="info"></p>
      </div>
    </div>
  </section>
</template>

<script>
import data from '@/data/content.json'
import ItemKeyFigure from '@/components/ui/ItemKeyFigure.vue'
import {Carousel, Slide, Pagination} from 'vue3-carousel'

export default {
  name: 'KeyFigure',
  components: {
    ItemKeyFigure,
    Carousel,
    Slide,
    Pagination
  },
  data() {
    return {
      KeyFigures: data.KeyFigure,
      info: data.keyfigure.info,
      itemsToShow: 1,
      breakpoints: {
        961: {
          itemsToShow: 4,
          mouseDrag: false,
          touchDrag: false
        }
      },
      ariaLabels: data.carousel.ariaLabels
    }
  },

}
</script>

<style lang="scss" scoped>
.keyfigure {
  min-height: 65rem;
  padding-top: 12rem;
  margin-bottom: $gutter-H;
  background-color: $c-white;
  background-image: url('@/assets/images/bg-keyfigure.png');
  background-repeat: no-repeat;
  background-size: cover;

  @include respond-to('medium up') {
    padding-top: $gutter-C;
    margin-bottom: 0;
    min-height: 71.2rem;
    background-size: auto;
  }

  &-info {
    position: relative;
    display: flex;
    justify-content: flex-end;
    top: 13rem;
    color: $c-primary-A;
    font-size: $fs-txt-D;
    font-style: italic;

    @include respond-to('small up') {
      font-size: $fs-txt-C;
    }

    p {
      text-align: right;

      @include respond-to('medium') {
        max-width: 80%;
      }
    }
  }
}
</style>
